<template>
  <div>
    <div class="mains"></div>
    <!-- <van-row
      class="main"
      style="margin-top:0rem;margin-bottom:0.5rem;background-color:#fff"
    >
      <van-col span="5" offset="1">
        <van-image
          :src="require('../../../assets/logo.png')"
          style="width:100%;height:70%;margin-top:0.5rem"
        />
      </van-col>
      <van-col span="18" class="headtext">
        <van-row style="margin-top:0.5rem">
          <van-col span="23" offset="1">
            工作工厂：{{ userData.tenantName }}
          </van-col>
        </van-row>
        <van-row>
          <van-col span="23" offset="1" style="margin-top:0.4rem">
            工号：{{ userData.loginId }} 姓名：{{ userData.nickName }}
          </van-col>
        </van-row>
        <van-row>
          <van-col span="23" offset="1" style="margin-top:0.4rem">
            今日工作：{{ userData.workload }}件 ￥{{ userData.wages }}
          </van-col>
        </van-row>
      </van-col>
    </van-row> -->
    <router-view></router-view>
    <van-tabbar v-model="active" active-color="#4fc08d">
      <van-tabbar-item replace to="/production" icon="wap-home-o"
        >生产中心</van-tabbar-item
      >
      <van-tabbar-item
        replace
        to="/production"
        :icon="require('../../../assets/seach.png')"
        @click="scanning"
        >扫一扫</van-tabbar-item
      >
      <van-tabbar-item replace to="/personal" icon="manager-o"
        >个人中心</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>

<script>
import { Tabbar, TabbarItem } from "vant";
import { Image as VanImage } from "vant";
import { Row, Col } from "vant";

export default {
  components: {
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Col.name]: Col,
  },
  created() {
    if (this.$route.query) {
      let { devicename } = this.$route.query;
      if (devicename) {
        if (
          devicename.toLowerCase().indexOf("iphone") != -1 ||
          devicename.toLowerCase().indexOf("ipad") != -1
        ) {
          this.phoneType = false;
        }
      }
    }
    if (localStorage.getItem("userData")) {
      this.userData = JSON.parse(localStorage.getItem("userData"));
    }
  },
  activated: function() {
    console.log(1);
  },
  data() {
    return {
      active: 2,
      userData: "",
      phoneType: true,
      name: "",
    };
  },
  mounted() {
    if (this.$route.path == "/personal") {
      this.active = 2;
    } else {
      this.active = 0;
    }
  },

  watch: {
    $route: {
      handler(val) {
        if (val.path == "/personal") {
          this.active = 2;
        } else {
          this.active = 0;
        }
      },
      deep: true,
    },
  },
  methods: {
    scanning() {
      if (this.phoneType) {
        android.startScan();
      } else {
        window.prompt("startScan");
      }
    },
    onClickCart() {
      this.$router.push("cart");
    },
  },
};
</script>
<style scoped>
.headtext {
  font-size: 0.7rem;
  color: grey;
}
.mains {
  width: 100%;
  height: 5rem;
}
.main {
  width: 100%;
  position: fixed;
  top: 0px;
  height: 5rem;
  z-index: 1;
}
</style>
